<template>
    <div>
        <Header></Header>
        <div class="content-main">
            <!-- 活动信息 -->
            <div class="jumbotron-wrap">
                <div class="jumbotron media" style="overflow: visible;">
                    <img class="pull-left media-object" style="width: 440px;height: 260px;" src="https://wimg.huodongxing.com/logo/202312/3734936561500/995103735914844_v2large.jpg@!wmlogo" alt="">
                    <div class="media-body">
                        <div class="media-heading">
                            <div class="detail-pp">新年聚会：DevOps全链路落地指南</div>
                        </div>
                        <div class="address-info-wrap">
                            <div class="">
                                <i class="iconfont icon-shijian3" style="margin-right:8px"></i>
                                <span>2024年1月20日 14:00 ～ 2024年1月20日 17:30</span>
                            </div>
                        </div>
                        <div class="address-info-wrap">
                            <div class="">
                                <i class="iconfont icon-dizhi1" style="margin-right:8px"></i>
                                <span>(上海济南) 南京西路1038号梅龙镇广场伊势丹百货7F 重影书局</span>
                            </div>
                        </div>
                        <div class="creator-box" title="活动发起人">
                            <a class="link-a-hover" href="http://sealsoftware.huodongxing.com" target="_blank">
                                <img class="face" src="https://cdn.huodongxing.com/logo/org/202206/2314562671301/134938739953354.png">
                                Seal软件
                            </a>
                        </div>
                    </div>
                    <div class="add-line-class"></div>
                    <div class="home_register_group_base">
                        <div class="new-ticket-wrap">
                            <span class="new-ticket-text"></span>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.content-main {
    width: 1080px;
    position: relative;
    margin: 70px auto;
}
/* 活动信息 */
.jumbotron-wrap {
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;
    border: solid 1px #eeeeee;
    margin-top: 30px;
    padding-bottom: 40px;
    background-color: #fff;
}
.jumbotron-wrap .jumbotron {
    height: auto;
}
.jumbotron.media {
    display: flex;
}
.media:first-child {
    margin-top: 0;
}
.jumbotron {
    margin-bottom: 0px;
    font-size: 14px;
    position: relative;
}
.pull-left.media-object {
    flex-shrink: 0;
    margin-top: -30px;
    margin-left: -30px;
    border: none;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    border-radius: 8px;
    margin-right: 20px;
}
.pull-left {
    float: left !important;
}
.media-object {
    display: block;
}
img {
    vertical-align: middle;
    width: 100%;
}
.media-body {
    overflow: visible;
    width: 100%;
    padding-right: 30px;
    position: relative;
}
.jumbotron .media-body .media-heading {
    overflow: initial;
    max-height: 62px;
    position: relative;
    padding-top: 30px;
    margin-bottom: 10px;
    box-sizing: content-box;
    font-family: Microsoft Yahei;
    font-size: 22px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 0 0 5px;
}
.detail-pp {
    margin-right: 15px;
    overflow: hidden;
    line-height: 1.3;
    max-height: 62px;
    width: 555px;
    font-size: 24px;
    color: #000000;
}
.creator-box {
    margin-bottom: 15px;
    margin-top: 20px;
}
.face {
    width: 27px;
    height: 27px;
    margin-right: 8px;
}
.add-line-class {
    margin: 0px 30px;
    box-sizing: border-box;
    height: 1px;
    background: #f0f2f5;
    margin-top: 26px;
}
.home_register_group_base {
    padding-left: 30px;
    padding-right: 150px;
    margin-top: 22px;
    margin-bottom: 0px !important;
}
</style>